.componentBase {
  display: inline-block;
  padding: var(--size-12) var(--size-32);
  border-radius: var(--radius-4);
  font-weight: var(--font-weight-600);
  font-size: var(--font-size-19);
}

.componentPrimary,
.componentLinkPrimary {
  background-color: var(--primary-element-background-color);
  color: var(--primary-element-color);
  box-shadow: 0 0 0 0 transparent;
  transition:
    box-shadow var(--duration-150) var(--ease-out-sine),
    opacity var(--duration-150) var(--ease-out-sine);

  &:hover,
  &:focus {
    box-shadow: var(--shadow-sm);
    background-color: var(--color-orange-400);
  }

  &:disabled {
    opacity: 0.5;
  }
}

.componentSecondary {
  background-color: transparent;
  color: var(--tertiary-element-color);
  border: 1px solid var(--tertiary-element-color);
  text-align: center;

  &:not(:disabled) {
    &:hover {
      background-color: var(--secondary-background-color);
      color: var(--tertiary-element-color-hover);
      border-color: var(--secondary-background-color);
    }

    &:focus {
      border-color: var(--secondary-background-color);
    }
  }

  &:disabled {
    opacity: 0.5;
  }
}

.componentTertiary,
.componentLinkTertiary {
  background-color: transparent;
  color: var(--tertiary-element-color);
  border: 1px solid var(--tertiary-element-color);
  text-align: center;

  &:not(:disabled) {
    &:hover {
      background-color: var(--tertiary-element-background-color-hover);
      color: var(--tertiary-element-color-hover);
      border-color: var(--tertiary-element-background-color-hover);
    }

    &:focus {
      border-color: var(--tertiary-element-background-color-hover);
    }
  }

  &:disabled {
    opacity: 0.5;
  }
}

.componentLinkWithIcon {
  align-items: center;
  gap: var(--size-8);
  color: var(--link-color);
  padding: 0;

  &:hover {
    & > .icon {
      background-color: var(--tertiary-element-background-color-hover);
      color: var(--tertiary-element-color-hover);
    }
  }
}

.componentWithIcon {
  align-items: center;
  gap: var(--size-8);
  padding: 0;
}

.icon {
  border: 1px solid var(--color);
  color: var(--color);
  border-radius: var(--radius-circular);
  display: flex;
  align-items: center;
  justify-content: center;
}

.componentLinkBase {
  border-radius: var(--radius-4);
  display: inline-flex;
  padding: var(--size-12) var(--size-32);
  justify-content: center;
  font-size: var(--font-size-19);
  font-weight: var(--font-weight-600);
}

.componentLinkIconBase,
.componentWithIcon {
  border-radius: var(--radius-4);
  display: inline-flex;
  padding: 0;

  &:focus-within {
    outline: 2px solid light-dark(
      var(--contrasting-card-border-color-light),
      var(--contrasting-card-border-color-dark)
    );
    outline-offset: var(--size-4);
    border-radius: var(--size-4);
  }

  & > .icon {
    width: 28px;
    height: 28px;
  }
}
