.component {
  background-color: var(--background-color);
  color: var(--color);

  & > .inner {
    height: 100vh;

    @media (--viewport-md) {
      height: 100%;
    }
  }
}

.inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: calc(var(--menu-padding) + var(--size-48));
  padding-bottom: var(--size-80);

  @media (--viewport-md) {
    padding-top: var(--menu-padding);
  }
}

.innerContainer {
  & > .content {
    margin-top: var(--size-32);

    @media (--viewport-md) {
      max-width: 565px;
      margin-left: auto;
      margin-top: var(--size-80);
    }
  }
}

.content {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  gap: var(--size-16);
  grid-template-areas:
    'text'
    'button1'
    'button2';

  @media (--viewport-md) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      'text text'
      'button1 button2';
  }

  & > .text {
    grid-area: text;
  }

  & > .button1Layout {
    grid-area: button1;
  }

  & > .button2Layout {
    grid-area: button2;
  }
}

.text {
  font-size: var(--font-size-22-25);
  font-style: italic;
}
